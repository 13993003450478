<template>
<nav class="p-0">
  <!--
   <router-link :to="{ name: 'first mission' }" class="text-decoration-none font2 fw-semibold">İlk Görev</router-link>
   -->
</nav>
  <router-view/>
<div id="Coridor">

</div>
</template>
<style scoped lang="scss">
#app {
  font-family: 'Poppins', sans-serif !important;
}
*{
   font-family: 'Poppins', sans-serif !important;
}
/* Hide scrollbar in Chrome, Safari and Opera */
 #app::-webkit-scrollbar {
   display:none;
 }

 /* Hide scrollbar for IE, Edge and Firefox */
 #app {
  -ms-overflow-style: none; /* IE and EDGE */;
   scrollbar-width: none; /* Firefox */
 }
</style>
<script>
import { defineComponent, onMounted, computed } from 'vue'
import { useAuthStore } from './store/auth'
import { useProfileStore } from './store/profile'

export default defineComponent({
  name: 'Coridor',
  setup () {
    const authStore = useAuthStore()
    const profileStore = useProfileStore()

    onMounted(async () => {
      // Eğer kullanıcı giriş yapmamışsa, profil bilgisi çağrılmıyor.
      if (!authStore.isLoggedIn) {
        // console.log("Kullanıcı giriş yapmamış, profil bilgisi çağrılmıyor.")
        return;
      }
      // Kullanıcı oturum açtığında profil verisini çek
      if (profileStore.profileSettings === null) {
        await profileStore.getProfileList();
      }
      // Kullanıcı profili yüklendikten sonra cinsiyet grubuna abone et
      if (profileStore.profileSettings) {
        subscribeToGenderGroup(profileStore.profileSettings.gender);
      }
      checkAuthAndLoadProfile();
    });

    return {
      authStore,
      profileStore
    }
  },
  components: {

  }
})
</script>
